import { Icon, Href } from '../../styles/Nav'



const LinkedIn = () => {
  return (
    <Href href="https://www.linkedin.com/in/dakota-arseneault/" target="_blank" rel="noopener noreferrer">
      <Icon version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512"  xmlXspace="preserve">
        <path d="m151 181h-60c-8.285156 0-15 6.714844-15 15v240c0 8.285156 6.714844 15 15 15h60c8.285156 0 15-6.714844 15-15v-240c0-8.285156-6.714844-15-15-15zm-15 240h-30v-210h30zm0 0" /><path d="m121 61c-24.8125 0-45 20.1875-45 45s20.1875 45 45 45 45-20.1875 45-45-20.1875-45-45-45zm0 60c-8.269531 0-15-6.730469-15-15s6.730469-15 15-15 15 6.730469 15 15-6.730469 15-15 15zm0 0" /><path d="m355.640625 181.925781c-24.507813-3.976562-49.59375.6875-69.738281 12.339844-.863282-7.46875-7.207032-13.265625-14.902344-13.265625h-60c-8.285156 0-15 6.714844-15 15v240c0 8.285156 6.714844 15 15 15h60c8.285156 0 15-6.714844 15-15v-135c0-16.542969 13.457031-30 30-30s30 13.457031 30 30v135c0 8.285156 6.714844 15 15 15h60c8.285156 0 15-6.714844 15-15v-161.460938c0-44.796874-31.660156-84.710937-80.359375-92.613281zm50.359375 239.074219h-30v-120c0-33.085938-26.914062-60-60-60s-60 26.914062-60 60v120h-30v-210h30v15c0 6.066406 3.65625 11.535156 9.261719 13.859375 5.601562 2.320313 12.054687 1.035156 16.34375-3.253906l6.996093-6.996094c14.726563-14.722656 39.15625-21.816406 62.234376-18.070313 35.308593 5.730469 55.164062 33.945313 55.164062 63zm0 0" /><path d="m436 0h-360c-41.484375 0-76 34.542969-76 76v360c0 41.484375 34.542969 76 76 76h360c41.484375 0 76-34.542969 76-76v-360c0-41.484375-34.542969-76-76-76zm46 436c0 24.933594-21.066406 46-46 46h-360c-24.933594 0-46-21.066406-46-46v-360c0-24.933594 21.066406-46 46-46h360c24.933594 0 46 21.066406 46 46zm0 0" />
      </Icon>
    </Href>

  )
}

export default LinkedIn