import { Icon, Href } from '../../styles/Nav'



const Vimeo = () => {
  return (
    <Href href="https://vimeo.com/dakotaarseneault" target="_blank" rel="noopener noreferrer">
    <Icon version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 496 496"  xmlXspace="preserve">
      <g>
        <g>
          <g>
            <path d="M339.808,105.04c-40.336,0-87.2,30.56-95.776,81.712c-1.024,6.16,1.616,12.336,6.752,15.856
				c5.152,3.52,11.872,3.728,17.232,0.528c6.448-3.856,10.528-4.432,12.384-4.432c2.224,0,2.656,0.704,3.024,1.328
				c1.472,2.48,2.656,10.208-3.536,21.664c-10.448,19.424-18.48,31.168-23.808,37.776c-4.128-11.584-8.88-30.48-11.28-40.016
				c-1.952-7.776-3.536-17.232-5.2-27.232c-6.352-37.984-14.256-85.248-54.096-85.248c-2.848,0-5.888,0.288-9.088,0.912
				c-42.256,8.256-91.488,65.424-96.96,71.92c-4.832,5.728-5.024,14.064-0.464,20.032l11.888,15.52
				c2.592,3.392,6.448,5.6,10.688,6.144c4.208,0.528,8.512-0.64,11.888-3.28c3.712-2.896,8.656-5.872,12.32-7.536
				c5.2,13.232,14.912,44.8,21.712,66.864c11.792,38.272,19.296,62.24,24.272,72.48c9.904,20.4,31.312,40.976,56.816,40.976
				c9.696,0,19.104-2.912,28-8.608C288,362.128,381.632,282.336,399.04,184.816c5.52-30.848-3.424-49.376-11.872-59.488
				C376.208,112.24,359.392,105.04,339.808,105.04z M367.552,179.168c-15.52,86.944-106.608,162.32-128.32,176.32
				c-3.68,2.384-7.168,3.536-10.672,3.536c-10.96,0-22.608-11.824-28.016-22.96c-4.24-8.768-13.936-40.192-22.464-67.92
				c-18.128-58.848-23.856-75.744-29.6-82.512c-4.56-5.36-11.2-8.304-18.704-8.304c-1.408,0-2.832,0.096-4.272,0.304
				c17.248-16.624,39.824-34.976,57.024-38.336c1.04-0.208,2.032-0.32,2.976-0.32c12.752,0,18.64,35.2,22.528,58.512
				c1.776,10.656,3.44,20.736,5.728,29.76c11.072,43.952,17.76,70.48,39.328,70.48c7.216,0,22.208,0,54.976-60.88
				c10.304-19.072,11.36-38.96,2.848-53.248c-5.552-9.28-14.576-15.152-25.264-16.592c12.896-18.976,35.008-30,54.16-30
				c7.04,0,16.704,1.536,22.816,8.832C368.496,152.848,370.192,164.368,367.552,179.168z"/>
            <path d="M362.768,0H133.232C59.76,0,0,59.76,0,133.232v229.536C0,436.24,59.76,496,133.232,496h229.536
				C436.24,496,496,436.24,496,362.784V133.232C496,59.76,436.24,0,362.768,0z M464,362.784
				c0,55.824-45.408,101.232-101.232,101.232H133.232C77.408,464.016,32,418.608,32,362.784V133.232C32,77.408,77.408,32,133.232,32
				h229.536C418.592,32,464,77.408,464,133.232V362.784z"/>
          </g>
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
    </Icon>
    </Href>

  )
}

export default Vimeo